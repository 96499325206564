<template>
  <th-middle-page-wrapper v-if="showSupportLogin">
    <!-- Support tag -->
    <el-tag class="support-tag">
      {{ $t('pages.auth.login_support.tag.label') }}
    </el-tag>

    <el-form ref="form" :model="form" :rules="rules">
      <!-- Client account ID -->
      <el-form-item
        prop="clientAccount"
        :label="$t('pages.auth.login_support.client_account.placeholder')"
      >
        <el-input
          id="clientAccount"
          v-model="form.clientAccount"
          class="hotjar-ignore"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="token"
        />
      </el-form-item>

      <!-- Password -->
      <el-form-item
        prop="token"
        :label="$t('pages.auth.login_support.token.placeholder')"
      >
        <el-input
          id="token"
          v-model="form.token"
          class="hotjar-ignore"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="token"
        />
      </el-form-item>

      <!-- Continue -->
      <el-button
        type="primary"
        class="w-full"
        :loading="loading"
        @click="handleSubmit"
      >
        {{ $t('pages.auth.login_support.continue') }}
      </el-button>
    </el-form>

    <div class="flex justify-between items-center text-xs mt-4">
      <!-- Version -->
      <version class="text-th-gray-base" />
    </div>
  </th-middle-page-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
import ThMiddlePageWrapper from '@components/middle-page-wrapper'
import Version from '@components/version'
import { waitForElement } from '@/utils/dom'
import { isKeycloackEnabled, isUnifiedCommerce } from '@/constants/index'

export default {
  components: {
    Version,
    ThMiddlePageWrapper
  },

  async beforeRouteLeave(to, from, next) {
    try {
      const captcha = await waitForElement('.grecaptcha-badge')
      if (captcha) {
        captcha.style.opacity = 0
        captcha.style['pointer-events'] = 'none'
      }
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
    next()
  },

  data() {
    let token = null
    let clientAccount = null
    // in all cases where a organisation is defined on the query paramaters and parsable, let's we assume
    // the caller wants to have an org login method.
    // This should be done gracefully, as we do want to test for good browser support or implementation bugs
    // throwing in this component - otherwise this component would be a lock-out
    try {
      const get = (n) => {
        const half = window.location.search.split(n + '=')[1]
        return half !== undefined
          ? window.decodeURIComponent(half.split('&')[0])
          : null
      }

      token = get('support_token')
      clientAccount = get('client_account')
    } catch (err) {
      // no-op
    }

    return {
      loading: false,
      form: {
        token,
        clientAccount
      },
      organisationInputActive: false,
      rules: {
        token: [
          {
            required: true,
            message: this.$t(
              'pages.auth.login_support.token.validation.required'
            ),
            trigger: 'blur'
          }
        ]
      },
      recaptchaToken: null
    }
  },

  computed: {
    ...mapState('Auth', ['authenticated']),
    showSupportLogin() {
      // Unified commerce whitelabel has a keycloak login
      return !isKeycloackEnabled() || !isUnifiedCommerce()
    }
  },

  async beforeCreate() {
    // NOTE: be aware that this triggers a couple of actions
    // - we want to force logout support users
    // - there state should be cleared
    // - we will redirect them to the login
    // However we need to make sure that we do not trigger any other actions such as the login when the componen mounts
    if (this.authenticated && this.showSupportLogin) {
      this.$router.push({
        path: '/logout',
        query: {
          redirect: this.$route.fullPath
        }
      })
      return
    }

    try {
      const captcha = await waitForElement('.grecaptcha-badge')
      if (captcha) {
        captcha.style.opacity = 1
        captcha.style['pointer-events'] = 'auto'
      }
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },

  mounted() {
    // NOTE: see comment above on redirect. We need to make sure that this login is not called more than once
    if (
      !this.authenticated &&
      this.form.token &&
      this.form.clientAccount &&
      this.showSupportLogin
    ) {
      this.handleSubmit()
    }

    // Support users that are coming from UFC, should be landed to /orders page.
    if (isUnifiedCommerce() && this.authenticated) {
      this.$router.push({
        path: '/orders/orders_transactions'
      })
    }
  },

  methods: {
    async handleSubmit() {
      if (!(await this.validate())) return

      this.checkRecaptcha((err, token) => {
        if (err) return this.$logException(err, { trackError: false })
        this.recaptchaToken = token

        this.login()
      })
    },
    async login() {
      this.loading = true
      const [err, { user } = {}] = await this.$store.dispatch(
        'Auth/getSupportAuthToken',
        {
          token: this.form.token,
          clientAccount: this.form.clientAccount,
          recaptchaToken: this.recaptchaToken || undefined
        }
      )
      this.loading = false

      if (err) {
        return this.$logException(err, {
          message: this.$t('pages.auth.login.error.message'),
          trackError: false
        })
      }

      this.$ampli.setOptOut(true)

      try {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: user })
        })
      } catch (err) {
        this.$logException(err, { trackError: false })
      }

      this.$message({
        message: this.$t('pages.auth.login.success.message'),
        type: 'success'
      })

      this.$log.debug('will route after success')

      this.$router.replace(this.$route.query.redirect || '/')

      this.$store.dispatch('Config/getClientAccountConfiguration')
    },

    checkRecaptcha(cb) {
      if (!window.grecaptcha) {
        return cb(
          new TypeError(
            'recaptcha does not seem to be available in this browser'
          )
        )
      }

      window.grecaptcha.ready(async () => {
        let token
        try {
          token = await window.grecaptcha.execute(
            '6Lde7r4UAAAAAEUZuRuaD11-W590yc5t-YXOZIo5',
            { action: 'login_support' }
          )
        } catch (err) {
          this.$log.debug(
            `recaptcha failed to retrieve token: ${err.message || err}`
          )

          return cb(err)
        }

        return cb(null, token)
      })
    },
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    reset() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped>
.support-tag {
  position: absolute;
  top: -5rem;
  right: -1rem;
}
</style>
